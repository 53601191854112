/* eslint jsx-a11y/anchor-is-valid: "off" */

import React from 'react';

export default function UserItem({userData, handleDelete, handleEdit}) {
  return (
    <tr>
      <td>
        <div className="dashboard_item dashboard_name">
          {userData.firstName + ' ' + userData.lastName}
        </div>
      </td>
      {/* <td>
        <div className="dashboard_item dashboard_name">{userData.role}</div>
      </td> */}
      <td>
        <div className="dashboard_item dashboard_name">{userData.email}</div>
      </td>

      <td>
        <div className="dashboard_item dashboard_name">{userData.days}</div>
      </td>

      <td>
        <a
          onClick={() => {
            handleEdit(userData);
          }}
          className="dashboard_remove"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.11 13.13" width={14} height={14}>
            <defs>
              <style dangerouslySetInnerHTML={{__html: '.cls-1{fill:#e84f13;}'}} />
            </defs>
            <g id="Calque_2" data-name="Calque 2">
              <g id="Calque_1-2" data-name="Calque 1">
                <path
                  className="cls-1"
                  d="M13.11,2.17a2.78,2.78,0,0,1-.91,1.24L9.7.91a6.67,6.67,0,0,1,.91-.74A1.13,1.13,0,0,1,12,.28a6.88,6.88,0,0,1,.89.9,2.42,2.42,0,0,1,.27.56Z"
                />
                <path className="cls-1" d="M9,1.77l2.42,2.42L3.74,11.83,1.32,9.41Z" />
                <path className="cls-1" d="M2.69,12.38,0,13.13l.74-2.69Z" />
              </g>
            </g>
          </svg>
        </a>
        <a
          onClick={() => {
            handleDelete(userData);
          }}
          className="dashboard_remove"
        >
          <svg
            width={14}
            height={14}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.33341 4.66666H11.6667V12.25C11.6667 12.4047 11.6053 12.5531 11.4959 12.6625C11.3865 12.7719 11.2381 12.8333 11.0834 12.8333H2.91675C2.76204 12.8333 2.61367 12.7719 2.50427 12.6625C2.39487 12.5531 2.33341 12.4047 2.33341 12.25V4.66666ZM3.50008 5.83332V11.6667H10.5001V5.83332H3.50008ZM5.25008 6.99999H6.41675V10.5H5.25008V6.99999ZM7.58342 6.99999H8.75008V10.5H7.58342V6.99999ZM4.08341 2.91666V1.74999C4.08341 1.59528 4.14487 1.44691 4.25427 1.33751C4.36367 1.22811 4.51204 1.16666 4.66675 1.16666H9.33342C9.48812 1.16666 9.6365 1.22811 9.74589 1.33751C9.85529 1.44691 9.91675 1.59528 9.91675 1.74999V2.91666H12.8334V4.08332H1.16675V2.91666H4.08341ZM5.25008 2.33332V2.91666H8.75008V2.33332H5.25008Z"
              fill="#E84503"
            />
          </svg>
        </a>
      </td>
    </tr>
  );
}
