/* eslint import/no-anonymous-default-export: "off" */

import {
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  REDIRECT_ACTION,
  REDIRECT_DISABLED,
  ACTIVE_LOADER,
} from '../../Const/ActionTypes';

const initialState = {
  error: false,
  success: false,
  message: '',
  redirect: false,
  urlToRedirect: '',
  loader: false,
};
export default function (state = initialState, action) {
  if (action.type === ACTIVE_LOADER) {
    return Object.assign({}, state, {
      loader: action.payload,
    });
  }
  if (action.type === REDIRECT_DISABLED) {
    return Object.assign({}, state, {
      redirect: false,
      urlToRedirect: action.payload,
    });
  }
  if (action.type === REDIRECT_ACTION) {
    return Object.assign({}, state, {
      redirect: true,
      urlToRedirect: action.payload,
    });
  }
  if (action.type === SHOW_ERROR_MESSAGE) {
    return Object.assign({}, state, {
      message: action.payload,
      error: true,
    });
  }

  if (action.type === HIDE_ERROR_MESSAGE) {
    return Object.assign({}, state, {
      error: false,
      message: '',
    });
  }
  if (action.type === SHOW_SUCCESS_MESSAGE) {
    return Object.assign({}, state, {
      message: action.payload,
      success: true,
    });
  }
  if (action.type === HIDE_SUCCESS_MESSAGE) {
    return Object.assign({}, state, {
      success: false,
      message: '',
    });
  }

  return state;
}
