import React from 'react';

const PrivateRoute = ({component: Component, authToken, ...rest}) => {
  if (!authToken) {
    window.location.replace(process.env.REACT_APP_SITE_PATH);
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
