/* eslint import/no-anonymous-default-export: "off" */

import {GET_API_KEYS_LIST, GENERATE_NEW_API_KEY, DELETE_API_KEY} from '../../Const/ActionTypes';

const initialState = {
  apiKeys: [],
  newApiKey: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_API_KEYS_LIST: {
      return Object.assign({}, state, {
        apiKeys: action.payload,
        newApiKey: undefined,
      });
    }
    case GENERATE_NEW_API_KEY: {
      return Object.assign({}, state, {
        newApiKey: action.payload.apiKey,
      });
    }
    case DELETE_API_KEY: {
      return Object.assign({}, state, {
        apiKeys: [...state.apiKeys.filter((element) => element.id !== action.payload)],
      });
    }

    default:
      return state;
  }
}
