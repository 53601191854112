import React, {useEffect} from 'react';

export default function AddApiKey({...props}) {
  useEffect(() => {
    let apikeyInput = document.getElementById('apikey');

    let formSubmit = document.getElementById('formSubmit');

    formSubmit.addEventListener('click', function (event) {
      if (apikeyInput.validity.valueMissing || apikeyInput.value.trim() === '') {
        apikeyInput.setCustomValidity('Please enter an api key name !');
      } else {
        apikeyInput.setCustomValidity('');
      }
    });
    apikeyInput.addEventListener('keyup', function (event) {
      apikeyInput.setCustomValidity('');
    });

    return () => {
      formSubmit.removeEventListener('click', {});
      apikeyInput.removeEventListener('keyup', {});
    };
  }, []);

  return (
    <div className="overlay active">
      <div className="overlay_form_wrapper">
        <form
          id="apiKeyForm"
          className="overlay_form form_api"
          onSubmit={(e) => {
            e.preventDefault();
            props.handleSubmit();
          }}
        >
          <div className="overlay_title">Enter a name for your new API Key</div>
          <fieldset>
            <label htmlFor="apikey">Name *</label>
            <input
              required
              type="text"
              id="apikey"
              name="apikey"
              onChange={(e) => {
                props.handleChange(e.target.value);
              }}
              value={props.apiKeyName}
              maxlength={50}
            />
          </fieldset>

          <div className="overlay_group d_flex">
            <button
              className="btn btn_primary btn_rectangle overlay_save "
              type="submit"
              id="formSubmit"
            >
              Save
            </button>
            <button
              className="btn btn_light btn_rectangle overlay_cancel"
              onClick={() => props.handleClose(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
