import React, {useEffect} from 'react';
import {isIP, isRange} from 'range_check';

export default function AddIpAdress({...props}) {
  useEffect(() => {
    let ipInput = document.getElementById('ip');
    let nameIpAdress = document.getElementById('nameIpAdress');
    let ipAdresseForm = document.getElementById('ipAdresseForm');

    ipAdresseForm.addEventListener('click', function (event) {
      if (ipInput.validity.valueMissing || ipInput.value.trim() === '') {
        ipInput.setCustomValidity('Please enter an IP address !');
      }
      // else if (!isIpAddress(ipInput.value)) {
      //   ipInput.setCustomValidity('Please enter a valid IP address !');
      // }
      else if (!isIP(ipInput.value) && !isRange(ipInput.value)) {
        ipInput.setCustomValidity('Please enter a valid IP address !');
      } else {
        ipInput.setCustomValidity('');
      }
      if (nameIpAdress.validity.valueMissing || nameIpAdress.value.trim() === '') {
        nameIpAdress.setCustomValidity('Please enter an IP address name !');
      } else {
        nameIpAdress.setCustomValidity('');
      }
    });
    ipInput.addEventListener('keyup', function (event) {
      ipInput.setCustomValidity('');
    });
    nameIpAdress.addEventListener('keyup', function (event) {
      nameIpAdress.setCustomValidity('');
    });
    return () => {
      ipAdresseForm.removeEventListener('click', {});
      ipInput.removeEventListener('keyup', {});
      nameIpAdress.removeEventListener('keyup', {});
    };
  }, []);

  return (
    <div className="overlay active">
      <div className="overlay_form_wrapper">
        <form
          id="ipAdresseForm"
          className="overlay_form form_api"
          onSubmit={(e) => props.handleSubmit(e)}
        >
          <div className="overlay_title">Enter your IP Address and choose a name for it</div>
          <fieldset>
            <label htmlFor="nameIpAdress"> Name *</label>
            <input
              required
              type="text"
              id="nameIpAdress"
              name="nameIpAdress"
              onChange={props.handleChange('nameAddress')}
              value={props.addIpAddress.nameAddress}
              maxlength={50}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="ip">IP Address *</label>
            <input
              required
              type="text"
              id="ip"
              name="ip"
              onChange={props.handleChange('ipAddress')}
              value={props.addIpAddress.ipAddress}
            />
          </fieldset>

          <div className="overlay_group d_flex">
            <button className="btn btn_primary btn_rectangle overlay_save " type="submit">
              Save
            </button>
            <button
              className="btn btn_light btn_rectangle overlay_cancel"
              onClick={() => props.handleClose(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
