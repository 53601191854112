/* eslint jsx-a11y/anchor-is-valid: "off" */

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AddIpAdress from './AddIpAdress';
import moment from 'moment';
import {
  getIpAddresses,
  addNewIpAddress,
  deleteIpAddress,
} from '../../../Redux/Actions/IpAddressAction';
import ConfirmModal from '../../../Components/Commun/ConfirmModal';
import {HIDE_ERROR_MESSAGE, SHOW_ERROR_MESSAGE} from '../../../Const/ActionTypes';

const IpAddressInitialState = {
  ipAddress: '',
  nameAddress: '',
};

export default function IpAddress() {
  const dispatch = useDispatch();
   const userProfile = useSelector((state) => state.authReducer.userProfile);

  const [addModal, setAddModal] = useState(false);
  const [addIpAddress, setIpAddress] = useState(IpAddressInitialState);
  const [ipAddressToDelete, setIpAddressToDelete] = useState('');
  const rows = useSelector((state) => state.IpAddressReducer.ipAddresses);
  const successStatus = useSelector((state) => state.alertReducer.success);
  const errorStatus = useSelector((state) => state.alertReducer.error);
  const message = useSelector((state) => state.alertReducer.message);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleDelete = () => {
    dispatch(deleteIpAddress(ipAddressToDelete));
    setConfirmModal(false);
  };

  const handleChange = (name) => (event) => {
    setIpAddress({...addIpAddress, [name]: event.target.value});
  };
  const ipAddressSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addNewIpAddress({
        ...addIpAddress,
        nameAddress: addIpAddress.nameAddress.trim(),
        createAt: new Date(),
        userId: userProfile.id,
      })
    );
    setAddModal(false);
    setIpAddress(IpAddressInitialState);
  };
  useEffect(() => {
    if (userProfile) {
      dispatch(getIpAddresses(userProfile.id));
    }
  }, [dispatch, userProfile]);

  return (
    <>
      <div className="dashboard_content">
        <div className="dashboard_header d_flex d_align_center d_justify_between">
          <h3 className="dashboard_title title">Your IP Address</h3>
          <a
            onClick={() => {
              if (rows.length > 2) {
                dispatch({
                  type: SHOW_ERROR_MESSAGE,
                  payload: 'You are not able to add more than 3 ip addresses .',
                });
                setTimeout(() => {
                  dispatch({type: HIDE_ERROR_MESSAGE});
                }, 2500);
              } else {
                setAddModal(true);
              }
            }}
            className="btn btn_outline_primary btn_rectangle dashboard_add dashboard_add_apikey"
          >
            <svg
              width={18}
              height={18}
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.25 8.25V5.25H9.75V8.25H12.75V9.75H9.75V12.75H8.25V9.75H5.25V8.25H8.25ZM9 16.5C4.85775 16.5 1.5 13.1422 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1422 13.1422 16.5 9 16.5ZM9 15C10.5913 15 12.1174 14.3679 13.2426 13.2426C14.3679 12.1174 15 10.5913 15 9C15 7.4087 14.3679 5.88258 13.2426 4.75736C12.1174 3.63214 10.5913 3 9 3C7.4087 3 5.88258 3.63214 4.75736 4.75736C3.63214 5.88258 3 7.4087 3 9C3 10.5913 3.63214 12.1174 4.75736 13.2426C5.88258 14.3679 7.4087 15 9 15V15Z"
                fill="#FF763F"
              />
            </svg>
            <span>Add new IP address</span>
          </a>
        </div>
        {successStatus && (
          <div className="action_success">
            <div className="login_success d_flex d_align_center d_justify_center">
              <span> {message} </span>
            </div>
          </div>
        )}
        {errorStatus && (
          <div className="login_failed">
            <div className="login_alert d_flex d_align_center d_justify_center">
              <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.57707 2.00001L14.9277 13C14.9862 13.1014 15.0171 13.2163 15.0171 13.3333C15.0171 13.4504 14.9863 13.5653 14.9277 13.6667C14.8692 13.768 14.7851 13.8522 14.6837 13.9107C14.5824 13.9692 14.4674 14 14.3504 14H1.64907C1.53205 14 1.41709 13.9692 1.31574 13.9107C1.2144 13.8522 1.13024 13.768 1.07173 13.6667C1.01322 13.5653 0.982421 13.4504 0.982422 13.3333C0.982423 13.2163 1.01323 13.1014 1.07174 13L7.4224 2.00001C7.48092 1.89867 7.56508 1.81452 7.66642 1.75601C7.76776 1.6975 7.88272 1.6667 7.99974 1.6667C8.11676 1.6667 8.23171 1.6975 8.33306 1.75601C8.4344 1.81452 8.51856 1.89867 8.57707 2.00001ZM2.80374 12.6667H13.1957L7.99974 3.66667L2.80374 12.6667ZM7.33307 10.6667H8.6664V12H7.33307V10.6667ZM7.33307 6.00001H8.6664V9.33334H7.33307V6.00001Z"
                  fill="#A86767"
                />
              </svg>
              <span> {message} </span>
            </div>
          </div>
        )}
        <div className="dashboard_main">
          <table className="dashboard_table">
            <thead className="thead dashboard_thead">
              <tr>
                <td>
                  <div className="dashboard_title">
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.75483 13.8247C3.71729 13.2467 2.85305 12.402 2.25153 11.3779C1.65001 10.3539 1.33307 9.18767 1.3335 8.00001C1.3335 4.31801 4.31816 1.33334 8.00016 1.33334C11.6822 1.33334 14.6668 4.31801 14.6668 8.00001C14.6673 9.18767 14.3503 10.3539 13.7488 11.3779C13.1473 12.402 12.283 13.2467 11.2455 13.8247L10.6482 12.6307C11.6688 12.0468 12.4679 11.142 12.9211 10.057C13.3743 8.97195 13.4562 7.76758 13.1542 6.63119C12.8521 5.49479 12.1829 4.49008 11.2507 3.77334C10.3186 3.0566 9.17569 2.668 7.99983 2.668C6.82397 2.668 5.68107 3.0566 4.74891 3.77334C3.81675 4.49008 3.14758 5.49479 2.8455 6.63119C2.54342 7.76758 2.62535 8.97195 3.07856 10.057C3.53177 11.142 4.33085 12.0468 5.3515 12.6307L4.75483 13.8247ZM5.9495 11.4353C5.19772 10.9866 4.61396 10.3035 4.28778 9.49099C3.9616 8.67848 3.91101 7.78139 4.14378 6.93736C4.37655 6.09332 4.87982 5.34898 5.57638 4.81852C6.27293 4.28806 7.12429 4.00079 7.99983 4.00079C8.87537 4.00079 9.72673 4.28806 10.4233 4.81852C11.1198 5.34898 11.6231 6.09332 11.8559 6.93736C12.0886 7.78139 12.0381 8.67848 11.7119 9.49099C11.3857 10.3035 10.8019 10.9866 10.0502 11.4353L9.4515 10.2373C9.93403 9.9243 10.3024 9.46363 10.5017 8.92406C10.701 8.3845 10.7204 7.79497 10.5572 7.24343C10.394 6.6919 10.0568 6.20792 9.59594 5.86372C9.13511 5.51952 8.57535 5.33355 8.00016 5.33355C7.42498 5.33355 6.86521 5.51952 6.40438 5.86372C5.94355 6.20792 5.60637 6.6919 5.44313 7.24343C5.2799 7.79497 5.29937 8.3845 5.49864 8.92406C5.69791 9.46363 6.06629 9.9243 6.54883 10.2373L5.9495 11.4353ZM8.00016 10.6667L10.0002 14.6667H6.00016L8.00016 10.6667Z"
                        fill="#7D8693"
                      />
                    </svg>
                    <span>Address IP</span>
                  </div>
                </td>
                <td>
                  <div className="dashboard_title">
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49724 9.99999H3.16924L1.8359 13.3333H0.399902L4.66657 2.66666H5.9999L10.2666 13.3333H8.83057L7.49724 9.99999ZM6.9639 8.66666L5.33324 4.58999L3.70257 8.66666H6.9639V8.66666ZM13.9999 8.35666V7.99999H15.3332V13.3333H13.9999V12.9767C13.5945 13.2105 13.1347 13.3335 12.6667 13.3333C12.1987 13.3332 11.739 13.2098 11.3338 12.9758C10.9285 12.7417 10.592 12.4051 10.3581 11.9997C10.1241 11.5944 10.0009 11.1347 10.0009 10.6667C10.0009 10.1987 10.1241 9.73891 10.3581 9.33358C10.592 8.92826 10.9285 8.59164 11.3338 8.35755C11.739 8.12347 12.1987 8.00015 12.6667 7.99999C13.1347 7.99983 13.5945 8.12284 13.9999 8.35666V8.35666ZM12.6666 12C13.0202 12 13.3593 11.8595 13.6094 11.6095C13.8594 11.3594 13.9999 11.0203 13.9999 10.6667C13.9999 10.313 13.8594 9.9739 13.6094 9.72385C13.3593 9.4738 13.0202 9.33332 12.6666 9.33332C12.3129 9.33332 11.9738 9.4738 11.7238 9.72385C11.4737 9.9739 11.3332 10.313 11.3332 10.6667C11.3332 11.0203 11.4737 11.3594 11.7238 11.6095C11.9738 11.8595 12.3129 12 12.6666 12V12Z"
                        fill="#7D8693"
                      />
                    </svg>
                    <span>Name</span>
                  </div>
                </td>
                <td colSpan={2}>
                  <div className="dashboard_title">
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.99992 0.666656V1.99999H9.99992V0.666656H11.3333V1.99999H13.9999C14.1767 1.99999 14.3463 2.07023 14.4713 2.19525C14.5963 2.32028 14.6666 2.48985 14.6666 2.66666V13.3333C14.6666 13.5101 14.5963 13.6797 14.4713 13.8047C14.3463 13.9298 14.1767 14 13.9999 14H1.99992C1.82311 14 1.65354 13.9298 1.52851 13.8047C1.40349 13.6797 1.33325 13.5101 1.33325 13.3333V2.66666C1.33325 2.48985 1.40349 2.32028 1.52851 2.19525C1.65354 2.07023 1.82311 1.99999 1.99992 1.99999H4.66659V0.666656H5.99992ZM13.3333 6.66666H2.66659V12.6667H13.3333V6.66666ZM10.0239 7.42399L10.9666 8.36666L7.66659 11.6667L5.30925 9.30932L6.25325 8.36666L7.66725 9.78132L10.0246 7.42399H10.0239ZM4.66659 3.33332H2.66659V5.33332H13.3333V3.33332H11.3333V3.99999H9.99992V3.33332H5.99992V3.99999H4.66659V3.33332Z"
                        fill="#7D8693"
                      />
                    </svg>
                    <span>Created at</span>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody className="dashboard_body">
              {rows.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className="dashboard_item dashboard_ip">{row.ipAddress}</div>
                  </td>
                  <td>
                    <div className="dashboard_item dashboard_name">{row.nameAddress}</div>
                  </td>
                  <td>
                    <div className="dashboard_item dashboard_date">
                      {moment(row.createAt).format('DD/MM/YY')}
                    </div>
                  </td>
                  <td>
                    <a
                      onClick={() => {
                        setIpAddressToDelete(row);
                        setConfirmModal(true);
                      }}
                      className="dashboard_remove"
                    >
                      <svg
                        width={14}
                        height={14}
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.33341 4.66666H11.6667V12.25C11.6667 12.4047 11.6053 12.5531 11.4959 12.6625C11.3865 12.7719 11.2381 12.8333 11.0834 12.8333H2.91675C2.76204 12.8333 2.61367 12.7719 2.50427 12.6625C2.39487 12.5531 2.33341 12.4047 2.33341 12.25V4.66666ZM3.50008 5.83332V11.6667H10.5001V5.83332H3.50008ZM5.25008 6.99999H6.41675V10.5H5.25008V6.99999ZM7.58342 6.99999H8.75008V10.5H7.58342V6.99999ZM4.08341 2.91666V1.74999C4.08341 1.59528 4.14487 1.44691 4.25427 1.33751C4.36367 1.22811 4.51204 1.16666 4.66675 1.16666H9.33342C9.48812 1.16666 9.6365 1.22811 9.74589 1.33751C9.85529 1.44691 9.91675 1.59528 9.91675 1.74999V2.91666H12.8334V4.08332H1.16675V2.91666H4.08341ZM5.25008 2.33332V2.91666H8.75008V2.33332H5.25008Z"
                          fill="#E84503"
                        />
                      </svg>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
       </div>
      {addModal && (
        <AddIpAdress
          handleClose={setAddModal}
          handleChange={handleChange}
          addIpAddress={addIpAddress}
          handleSubmit={ipAddressSubmit}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          handleSubmit={handleDelete}
          handleClose={setConfirmModal}
          modalMessage={
            'You are going to delete an IP address with name : ' + ipAddressToDelete.nameAddress
          }
        />
      )}
    </>
  );
}
