/* eslint jsx-a11y/anchor-is-valid: "off" */

import React from 'react';
import {useSelector} from 'react-redux';

export default function Settings() {
  const currentUser = useSelector((state) => state.userReducer.user);

  return (
    <div className="dashboard_content">
      <div className="dashboard_header d_flex d_align_center d_justify_between">
        <h3 className="dashboard_title title">Settings</h3>
      </div>

      <div className="dashboard_main">
        <form className="settings_form">
          <fieldset className="settings_form_info_first settings_form_info">
            <label htmlFor="mail">Email</label>
            <input type="email" name="mail" id="mail" value={currentUser.email} disabled />
          </fieldset>
          <fieldset className="settings_form_info">
            <label htmlFor="company">Company</label>
            <input type="text" name="company" id="company" value={currentUser.society} disabled />
          </fieldset>
        </form>
      </div>
     </div>
  );
}
