export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const HIDE_ERROR_MESSAGE = 'HIDE_ERROR_MESSAGE';
export const HIDE_SUCCESS_MESSAGE = 'HIDE_SUCCESS_MESSAGE';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const REDIRECT_ACTION = 'REDIRECT_ACTION';
export const REDIRECT_DISABLED = 'REDIRECT_DISABLED';
export const ACTIVE_LOADER = 'ACTIVE_LOADER';




export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';

export const GET_USERS_LIST = 'GET_USERS_LIST';
export const SUBMIT_USER = 'SUBMIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const EDIT_USER = 'EDIT_USER';
export const GET_USER = 'GET_USER';

export const GET_API_KEYS_LIST = 'GET_API_KEYS_LIST';
export const GENERATE_NEW_API_KEY = 'GENERATE_NEW_API_KEY';
export const DELETE_API_KEY = 'DELETE_API_KEY';

export const ADD_IP_ADDRESS = 'ADD_IP_ADDRESS';
export const GET_IP_ADDRESS_LIST = 'GET_IP_ADDRESS_LIST';
export const DELETE_IP_ADDRESS = 'DELETE_IP_ADDRESS';
