import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {getUser} from '../../Redux/Actions/UserAction';

export default function Callback() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authToken = useSelector((state) => state.authReducer.authToken);
  const userProfile = useSelector((state) => state.authReducer.userProfile);

  useEffect(() => {
    if (window.location.hash) {
      var hash = window.location.hash.substring(1);
      let token = hash.toString();
      let decoded = token.match('access_token=(.*)');
      window.localStorage.setItem('mUfjka563lPs', decoded[1]);
      if (decoded[1]) {
        console.log('Callback:App.js============ ');

        dispatch(getUser());
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (authToken && userProfile) {
      navigate('/api-keys');
    }
  }, [navigate, authToken, userProfile]);
  return <div> </div>;
}
