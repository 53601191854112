export const roleList = ["admin", "developer"];
export const statusList = ["active", "inactive"];
export const allCountriesList = [
  "European Union",
  "Aruba",
  "Afghanistan",
  "Angola",
  "Anguilla",
  "Åland Islands",
  "Albania",
  "Andorra",
  "United Arab Emirates",
  "Argentina",
  "Armenia",
  "American Samoa",
  "Antarctica",
  "French Southern Territories",
  "Antigua and Barbuda",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Burundi",
  "Belgium",
  "Benin",
  "Bonaire, Sint Eustatius and Saba",
  "Burkina Faso",
  "Bangladesh",
  "Bulgaria",
  "Bahrain",
  "Bahamas",
  "Bosnia and Herzegovina",
  "Saint Barthélemy",
  "Belarus",
  "Belize",
  "Bermuda",
  "Bolivia",
  "Brazil",
  "Barbados",
  "Brunei Darussalam",
  "Bhutan",
  "Bouvet Island",
  "Botswana",
  "Central African Republic",
  "Canada",
  "Cocos (Keeling) Islands",
  "Switzerland",
  "Chile",
  "China",
  "Côte d'Ivoire",
  "Cameroon",
  "Congo",
  "Congo",
  "Cook Islands",
  "Colombia",
  "Comoros",
  "Cabo Verde",
  "Costa Rica",
  "Cuba",
  "Curaçao",
  "Christmas Island",
  "Cayman Islands",
  "Cyprus",
  "Czechia",
  "Germany",
  "Djibouti",
  "Dominica",
  "Denmark",
  "Dominican Republic",
  "Algeria",
  "Ecuador",
  "Egypt",
  "Eritrea",
  "Western Sahara",
  "Spain",
  "Estonia",
  "Ethiopia",
  "Finland",
  "Fiji",
  "Falkland Islands (Malvinas)",
  "France",
  "Faroe Islands",
  "Micronesia",
  "Gabon",
  "United Kingdom",
  "Georgia",
  "Guernsey",
  "Ghana",
  "Gibraltar",
  "Guinea",
  "Guadeloupe",
  "Gambia",
  "Guinea-Bissau",
  "Equatorial Guinea",
  "Greece",
  "Grenada",
  "Greenland",
  "Guatemala",
  "French Guiana",
  "Guam",
  "Guyana",
  "Hong Kong",
  "Heard Island and McDonald Islands",
  "Honduras",
  "Croatia",
  "Haiti",
  "Hungary",
  "Indonesia",
  "Isle of Man",
  "India",
  "British Indian Ocean Territory",
  "Ireland",
  "Iran",
  "Iraq",
  "Iceland",
  "Israel",
  "Italy",
  "Jamaica",
  "Jersey",
  "Jordan",
  "Japan",
  "Kazakhstan",
  "Kenya",
  "Kyrgyzstan",
  "Cambodia",
  "Kiribati",
  "Saint Kitts and Nevis",
  "Korea",
  "Kuwait",
  "Lao People's Democratic Republic",
  "Lebanon",
  "Liberia",
  "Libya",
  "Saint Lucia",
  "Liechtenstein",
  "Sri Lanka",
  "Lesotho",
  "Lithuania",
  "Luxembourg",
  "Latvia",
  "Macao",
  "Saint Martin (French part)",
  "Morocco",
  "Monaco",
  "Moldova",
  "Madagascar",
  "Maldives",
  "Mexico",
  "Marshall Islands",
  "North Macedonia",
  "Mali",
  "Malta",
  "Myanmar",
  "Montenegro",
  "Mongolia",
  "Northern Mariana Islands",
  "Mozambique",
  "Mauritania",
  "Montserrat",
  "Martinique",
  "Mauritius",
  "Malawi",
  "Malaysia",
  "Mayotte",
  "Namibia",
  "New Caledonia",
  "Niger",
  "Norfolk Island",
  "Nigeria",
  "Nicaragua",
  "Niue",
  "Netherlands",
  "Norway",
  "Nepal",
  "Nauru",
  "New Zealand",
  "Oman",
  "Pakistan",
  "Panama",
  "Pitcairn",
  "Peru",
  "Philippines",
  "Palau",
  "Papua New Guinea",
  "Poland",
  "Puerto Rico",
  "Korea",
  "Portugal",
  "Paraguay",
  "Palestine",
  "French Polynesia",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saudi Arabia",
  "Sudan",
  "Senegal",
  "Singapore",
  "South Georgia and the South Sandwich Islands",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Svalbard and Jan Mayen",
  "Solomon Islands",
  "Sierra Leone",
  "El Salvador",
  "San Marino",
  "Somalia",
  "Saint Pierre and Miquelon",
  "Serbia",
  "South Sudan",
  "Sao Tome and Principe",
  "Suriname",
  "Slovakia",
  "Slovenia",
  "Sweden",
  "Eswatini",
  "Sint Maarten (Dutch part)",
  "Seychelles",
  "Syrian Arab Republic",
  "Turks and Caicos Islands",
  "Chad",
  "Togo",
  "Thailand",
  "Tajikistan",
  "Tokelau",
  "Turkmenistan",
  "Timor-Leste",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Tuvalu",
  "Taiwan",
  "Tanzania",
  "Uganda",
  "Ukraine",
  "United States Minor Outlying Islands",
  "Uruguay",
  "United States",
  "Uzbekistan",
  "Holy See (Vatican City State)",
  "Saint Vincent and the Grenadines",
  "Venezuela",
  "Virgin Islands, British",
  "Virgin Islands",
  "Viet Nam",
  "Vanuatu",
  "Wallis and Futuna",
  "Samoa",
  "Yemen",
  "South Africa",
  "Zambia",
  "Zimbabwe",
];
