import React, { useState } from "react";

export default function CopyApiKey({ ...props }) {
  const [copyAction, setCopyAction] = useState(false);

  const handleCopy = () => {
    if (props.newApiKey) {
      navigator.clipboard.writeText(props.newApiKey);
      setCopyAction("Copied ✔");
      setTimeout(() => {
        setCopyAction(false);
      }, 2000);
    }
  };
  return (
    <div className="overlay active">
      <div className="overlay_form_wrapper">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCopy();
          }}
          className="overlay_form form_api"
        >
          {copyAction && (
            <div>
              <div className="login_success d_flex d_align_center d_justify_center">
                <span> {copyAction} </span>
              </div>
            </div>
          )}

          <div className="overlay_title"> {props.modalMessage} </div>
          <fieldset>
            <label htmlFor="apikey">{props.newApiKey}</label>
          </fieldset>
          <div className="overlay_group d_flex">
            <button
              className="btn btn_primary btn_rectangle overlay_save "
              type="submit"
            >
              Copy
            </button>
            <button
              className="btn btn_light btn_rectangle overlay_cancel"
              onClick={() => {
                props.handleClose();
              }}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
