/* eslint eqeqeq: "off" */

module.exports = {
  // isIpAddress(value) {
  //   if (value != null) {
  //     let validIpAddress =
  //       /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
  //         value
  //       );
  //     return validIpAddress;
  //   } else {
  //     return false;
  //   }
  // },
  isValidPassWord(value) {
    if (value != null) {
      let validPassword =
        value.match(/[0-9]/g) &&
        value.match(/[A-Z]/g) &&
        value.match(/[a-z]/g) &&
        value.match(/[^a-zA-Z\d]/g) &&
        value.length >= 8;
      return validPassword;
    } else {
      return false;
    }
  },
  isEmail(email) {
    let value = email.trim();
    if (value != null) {
      if (value.length > 0)
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        );
      else return true;
    } else {
      return false;
    }
  },
  isIpAddress(value) {
    if (value != null) {
      if (value.length > 0)
        return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          value
        );
      else return true;
    } else {
      return false;
    }
  },
  isValidateInput(value) {
    if (value != '') {
      let isValidateInput = value.trim() && value.length > 0;
      return isValidateInput;
    } else {
      return false;
    }
  },
  isEmpty(value) {
    if (value != null && value.length > 0) {
      return value.trim() === '';
    }
  },
};
