/* eslint jsx-a11y/anchor-is-valid: "off" */

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getUsers} from '../../../Redux/Actions/UserAction';
import AddUser from './AddUser';
import UserList from './UserList';

export default function Users() {
  const dispatch = useDispatch();
  const usersList = useSelector((state) => state.userReducer.users);
  const successStatus = useSelector((state) => state.alertReducer.success);
  const errorStatus = useSelector((state) => state.alertReducer.error);
  const message = useSelector((state) => state.alertReducer.message);
  const [userForm, setUserForm] = useState({open: false});

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const openUserForm = (type, defaultData) => {
    setUserForm({type, defaultData, open: true});
  };
  return (
    <div className="dashboard_content">
      <div className="dashboard_header d_flex d_align_center d_justify_between">
        <h3 className="dashboard_title title">
          {userForm.type === 'add'
            ? 'Add user'
            : userForm.type === 'edit'
            ? 'Edit user'
            : 'Users list'}
        </h3>
        <a
          onClick={() => {
            userForm.open ? setUserForm({open: false}) : openUserForm('add');
          }}
          className="btn btn_outline_primary btn_rectangle dashboard_add dashboard_add_apikey"
        >
          {userForm.open ? (
            <span>Cancel</span>
          ) : (
            <>
              <svg
                width={18}
                height={18}
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.25 8.25V5.25H9.75V8.25H12.75V9.75H9.75V12.75H8.25V9.75H5.25V8.25H8.25ZM9 16.5C4.85775 16.5 1.5 13.1422 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1422 13.1422 16.5 9 16.5ZM9 15C10.5913 15 12.1174 14.3679 13.2426 13.2426C14.3679 12.1174 15 10.5913 15 9C15 7.4087 14.3679 5.88258 13.2426 4.75736C12.1174 3.63214 10.5913 3 9 3C7.4087 3 5.88258 3.63214 4.75736 4.75736C3.63214 5.88258 3 7.4087 3 9C3 10.5913 3.63214 12.1174 4.75736 13.2426C5.88258 14.3679 7.4087 15 9 15V15Z"
                  fill="#FF763F"
                />
              </svg>
              <span>Add new user</span>
            </>
          )}
        </a>
      </div>

      {successStatus && (
        <div className="action_success">
          <div className="login_success d_flex d_align_center d_justify_center">
            <span> {message} </span>
          </div>
        </div>
      )}
      {errorStatus && (
        <div className="login_failed">
          <div className="login_alert d_flex d_align_center d_justify_center">
            <svg
              width={16}
              height={16}
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.57707 2.00001L14.9277 13C14.9862 13.1014 15.0171 13.2163 15.0171 13.3333C15.0171 13.4504 14.9863 13.5653 14.9277 13.6667C14.8692 13.768 14.7851 13.8522 14.6837 13.9107C14.5824 13.9692 14.4674 14 14.3504 14H1.64907C1.53205 14 1.41709 13.9692 1.31574 13.9107C1.2144 13.8522 1.13024 13.768 1.07173 13.6667C1.01322 13.5653 0.982421 13.4504 0.982422 13.3333C0.982423 13.2163 1.01323 13.1014 1.07174 13L7.4224 2.00001C7.48092 1.89867 7.56508 1.81452 7.66642 1.75601C7.76776 1.6975 7.88272 1.6667 7.99974 1.6667C8.11676 1.6667 8.23171 1.6975 8.33306 1.75601C8.4344 1.81452 8.51856 1.89867 8.57707 2.00001ZM2.80374 12.6667H13.1957L7.99974 3.66667L2.80374 12.6667ZM7.33307 10.6667H8.6664V12H7.33307V10.6667ZM7.33307 6.00001H8.6664V9.33334H7.33307V6.00001Z"
                fill="#A86767"
              />
            </svg>
            <span> {message} </span>
          </div>
        </div>
      )}
      <div className="dashboard_main">
        {userForm.open ? (
          <AddUser
            type={userForm.type}
            defaultData={userForm.defaultData}
            handleClose={() => setUserForm({open: false})}
          />
        ) : (
          <UserList usersList={usersList} openUserForm={openUserForm} />
        )}
      </div>

      {/* <div className="dashboard_copy">Copyright © Prioritis 2022</div> */}
    </div>
  );
}
