import {
  GET_API_KEYS_LIST,
  GENERATE_NEW_API_KEY,
  DELETE_API_KEY,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE
} from "../../Const/ActionTypes";
import { crudService } from "../../Services/CrudService";
// export const getApiKeys = (userId) => {
//   return (dispatch) => {
//     axios
//       .get(`${baseUrl.baseUrl}/api-keys/user/${userId}`, {
//         headers: {
//           Authorization: `Bearer ${localStorage.mUfjka563lPs}`,
//         },
//       })
//       .then((res) => {
//         let dataStored = res.data.sort(function (a, b) {
//           return new Date(b.createAt) - new Date(a.createAt);
//         });
//         dispatch({
//           type: GET_API_KEYS_LIST,
//           payload: dataStored,
//         });
//       })

//       .catch((err) => {
//         dispatch({
//           type: SHOW_ERROR_MESSAGE,
//           payload: err.message,
//         });
//         setTimeout(() => {
//           dispatch({type: HIDE_ERROR_MESSAGE});
//         }, 4000);
//       })
//       .finally(() => window.scrollTo(0, 0));
//   };
// };

export const getApiKeys = (userId) => {
  return (dispatch) => {
    let apiEndpoint = `/api-keys/user/${userId}`;
    crudService
      .get(apiEndpoint)
      .then((res) => {
        if (res) {
          let dataStored = res.data.sort(function (a, b) {
            return new Date(b.createAt) - new Date(a.createAt);
          });
          dispatch({
            type: GET_API_KEYS_LIST,
            payload: dataStored,
          });
        }
      })
      .finally(() => window.scrollTo(0, 0));
  };
};

export const generateApiKey = (data) => {
  return (dispatch) => {
    let apiEndpoint = `/api-keys`;
    crudService.post(apiEndpoint, data)
      .then((res) => {
        if (res) {
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload: `New API key generated successfully`,
          });
          dispatch({
            type: GENERATE_NEW_API_KEY,
            payload: res.data,
          });
          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);
        }
      
      })
      .finally(() => window.scrollTo(0, 0));
  };
};

export const deleteApiKey = (ApiKey) => {
  return (dispatch) => {
    let apiEndpoint = `/api-keys/${ApiKey.id}`;
    crudService
      .deleteEndpoint(apiEndpoint)
      .then((res) => {
        if (res) {
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload: `API key deleted successfully`,
          });
          dispatch({
            type: DELETE_API_KEY,
            payload: ApiKey.id,
          });
          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);
        }
    
      })
      .finally(() => window.scrollTo(0, 0));
  };
};
