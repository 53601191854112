import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {allCountriesList} from '../../../Const/selectListValues';
import {editUser, submitUser} from '../../../Redux/Actions/UserAction';

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const userInitialState = {
  firstName: '',
  lastName: '',
  status: 'inactive',
  email: '',
  occupation: '',
  society: '',
  country: '',
  role: '',
  type: '',
  iqviaId: '',
  dateSessionStart: new Date().toISOString().slice(0, 10),
  dateSessionEnd: tomorrow.toISOString().slice(0, 10),
};
export default function AddUser({type, defaultData, handleClose}) {
  const dispatch = useDispatch();

  const [userData, setUserData] = useState(userInitialState);

  useEffect(() => {
    if (type === 'add') {
      setUserData(userInitialState);
    } else {
      setUserData({
        ...defaultData,
        dateSessionStart: new Date(defaultData.dateSessionStart).toISOString().slice(0, 10),
        dateSessionEnd: new Date(defaultData.dateSessionEnd).toISOString().slice(0, 10),
      });
    }

    let userForm = document.getElementById('userForm');
    let formSubmit = document.getElementById('formSubmit');

    for (let i = 0; i < userForm.elements.length; i++) {
      if (userForm.elements[i].nodeName === 'INPUT' || userForm.elements[i].nodeName === 'SELECT') {
        userForm.elements[i].addEventListener('keyup', function (event) {
          userForm.elements[i].setCustomValidity(' ');
        });
      }
    }

    formSubmit.addEventListener('click', function (event) {
      for (let i = 0; i < userForm.elements.length; i++) {
        if (
          userForm.elements[i].nodeName === 'INPUT' ||
          userForm.elements[i].nodeName === 'SELECT'
        ) {
          if (
            userForm.elements[i].validity.valueMissing &&
            userForm.elements[i].value.trim() === ''
            
          ) {
 
            userForm.elements[i].setCustomValidity('Please complete this field !');
          } else {
            userForm.elements[i].setCustomValidity('');
          }
          if (userForm.elements[i].id === 'email') {
            if (userForm.elements[i].validity.typeMismatch) {
              userForm.elements[i].setCustomValidity('Please respect the email format !');
            } else if (userForm.elements[i].validity.valueMissing) {
              userForm.elements[i].setCustomValidity('Please complete this field !');
            } else {
              userForm.elements[i].setCustomValidity('');
            }
          }
          if (userForm.elements[i].id === 'dateSessionEnd') {
            if (userForm.elements[i].validity.rangeUnderflow) {
              userForm.elements[i].setCustomValidity('End date must be after start date !');
            } else {
              userForm.elements[i].setCustomValidity('');
            }
          }
        }
      }
    });

    return () => {
      userForm.removeEventListener('click', {});
      for (let i = 0; i < userForm.elements.length; i++) {
        if (
          userForm.elements[i].nodeName === 'INPUT' ||
          userForm.elements[i].nodeName === 'SELECT'
        ) {
          userForm.elements[i].removeEventListener('keyup', {});
        }
      }
    };
  }, [type, defaultData]);

  let startDate = new Date(userData.dateSessionStart);
  let minEndDate = startDate.setDate(startDate.getDate() + 1);

  const handleSubmit = () => {
    if (type === 'add') {
      let newUser = {
        ...userData,
        dateSessionStart: new Date(userData.dateSessionStart),
        dateSessionEnd: new Date(userData.dateSessionEnd),
        createdAt: new Date(),
        updateAt: new Date(),
        firstConnexion: false,
        lastName: userData.lastName.toUpperCase(),
        firstName: userData.firstName.charAt(0).toUpperCase() + userData.firstName.slice(1),
      };
      dispatch(submitUser(newUser));
    } else {
      let newuser = {
        ...userData,
        dateSessionStart: new Date(userData.dateSessionStart),
        dateSessionEnd: new Date(userData.dateSessionEnd),
        lastName: userData.lastName.toUpperCase(),
        firstName: userData.firstName.charAt(0).toUpperCase() + userData.firstName.slice(1),
        updateAt: new Date(),
      };
      dispatch(editUser(newuser));
    }

    handleClose();
    setUserData(userInitialState);
  };
  const handleChange = (event) => {
    setUserData({...userData, [event.target.name]: event.target.value});
  };
  return (
    <form
      id="userForm"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      className="settings_form"
    >
      <div className="settings_title">Profile</div>
      <fieldset>
        <label htmlFor="lastName">Last name *</label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          value={userData.lastName}
          onChange={(e) => handleChange(e)}
          required
        />
      </fieldset>

      <fieldset>
        <label htmlFor="firstName">First name *</label>
        <input
          required
          type="text"
          name="firstName"
          id="firstName"
          value={userData.firstName}
          onChange={(e) => handleChange(e)}
        />
      </fieldset>

      <fieldset>
        <label htmlFor="email">Email *</label>
        <input
          required
          type="email"
          name="email"
          id="email"
          value={userData.email}
          onChange={(e) => handleChange(e)}
        />
      </fieldset>

      <fieldset>
        <label htmlFor="occupation">Occupation </label>
        <input
          type="text"
          name="occupation"
          id="occupation"
          value={userData.occupation}
          onChange={(e) => handleChange(e)}
        />
      </fieldset>

      <fieldset>
        <label htmlFor="society">Society </label>
        <input
          type="text"
          name="society"
          id="society"
          value={userData.society}
          onChange={(e) => handleChange(e)}
        />
      </fieldset>

      <fieldset>
        <label htmlFor="country">Country </label>
        <select
          name="country"
          id="country"
          value={userData.country}
          onChange={(e) => handleChange(e)}
        >
          <option value=""></option>
          {allCountriesList.map((contryElement, index) => (
            <option key={index} value={contryElement}>
              {contryElement}
            </option>
          ))}
        </select>
      </fieldset>

      <div
        style={{
          paddingBottom: '35px',
          borderTop: '2px solid #FFF9F6',
          marginBottom: '0',
        }}
      ></div>
      <div className="settings_title">Identifier</div>
      <fieldset>
        <label htmlFor="role">Type *</label>
        <select
          required
          name="type"
          id="type"
          value={userData.type}
          onChange={(e) => handleChange(e)}
        >
          <option value=""></option>
          <option value="internal">Internal</option>
          <option value="external">External</option>
          <option value="federated">Federated</option>
        </select>
      </fieldset>

      {userData.type === 'internal' && (
        <fieldset>
          <label htmlFor="iqviaId">IQVIA ID *</label>
          <input
            required
            type="text"
            name="iqviaId"
            id="iqviaId"
            value={userData.iqviaId}
            onChange={(e) => handleChange(e)}
          />
        </fieldset>
      )}

      <fieldset>
        <label htmlFor="role">Role *</label>
        <select
          required
          name="role"
          id="role"
          value={userData.role}
          onChange={(e) => handleChange(e)}
        >
          <option value=""></option>
          <option value="admin">Admin</option>
          <option value="developer">Developer</option>
        </select>
      </fieldset>
      <fieldset>
        <label htmlFor="status">Status *</label>

        <select
          required
          name="status"
          id="status"
          value={userData.status}
          onChange={(e) => handleChange(e)}
        >
          <option value=""></option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </fieldset>

      <div
        style={{
          paddingBottom: '35px',
          borderTop: '2px solid #FFF9F6',
          marginBottom: '0',
        }}
      ></div>
      <div className="settings_title">Session</div>
      <fieldset>
        <label htmlFor="dateSessionStart">Start date * </label>
        <input
          required
          type="date"
          name="dateSessionStart"
          id="dateSessionStart"
          value={userData.dateSessionStart}
          onChange={(e) => handleChange(e)}
          min={type === 'add' ? new Date().toISOString().slice(0, 10) : undefined}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="dateSessionEnd">End date *</label>
        <input
          required
          type="date"
          name="dateSessionEnd"
          id="dateSessionEnd"
          min={new Date(minEndDate).toISOString().slice(0, 10)}
          value={userData.dateSessionEnd}
          onChange={(e) => handleChange(e)}
        />
      </fieldset>

      <button
        id="formSubmit"
        type="submit"
        className="settings_form_btn btn btn_primary btn_rectangle"
      >
        Save
      </button>
      <button className="btn btn_light btn_rectangle overlay_cancel" onClick={() => handleClose()}>
        Cancel
      </button>
    </form>
  );
}
