import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes} from 'react-router-dom';
import {getUser} from './Redux/Actions/UserAction';
import NotFoundPage from './Components/Authentification/NotFoundPage';
import PrivateRoute from './Components/Commun/PrivateRoute';
import Documentation from './Components/Modules/Documentation/Documentation';
import Dashboard from './Components/Modules/Dashboard';
import Callback from './Components/Authentification/Callback';

function App() {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.alertReducer.loader);

  const authToken = useSelector((state) => state.authReducer.authToken);
  const userProfile = useSelector((state) => state.authReducer.userProfile);

  useEffect(() => {
    if (authToken && userProfile === undefined) {
      dispatch(getUser());
    }
  }, [dispatch, authToken, userProfile]);

  return (
    <>
      {loader && <div className="loader"></div>}{' '}
      <Routes>
        {userProfile?.role === 'admin' && (
          <Route
            path="/users"
            element={
              <PrivateRoute
                authToken={authToken}
                userData={userProfile}
                component={Dashboard}
                defaultActiveItem="Users"
              />
            }
          />
        )}

        <Route
          path="/api-keys"
          element={
            <PrivateRoute
              authToken={authToken}
              userData={userProfile}
              component={Dashboard}
              defaultActiveItem="APIKeys"
            />
          }
        />
        <Route
          path="/ip-addresses"
          element={
            <PrivateRoute
              authToken={authToken}
              userData={userProfile}
              component={Dashboard}
              defaultActiveItem="ApprovedIPs"
            />
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute
              authToken={authToken}
              userData={userProfile}
              component={Dashboard}
              defaultActiveItem="Settings"
            />
          }
        />
        <Route
          path="/documentation"
          element={<PrivateRoute authToken={authToken} component={Documentation} />}
        />
        <Route path="*" element={<NotFoundPage role={userProfile?.role} />} />

        {/* <Route path="/forgot-password" element={<Forgot />} /> */}
        <Route path="/callback" element={<Callback />} />
        {/* <Route path="admin/signin" element={userProfile?.role ? <Navigate to="/users" /> : <SignIn />} /> */}
        {/* <Route
              path="developer/signin"
              element={authToken ? <Navigate to="/api-keys" /> : <SignIn />}
                /> */}
      </Routes>
    </>
  );
}

export default App;
