/* eslint import/no-anonymous-default-export: "off" */

import { ADD_IP_ADDRESS, GET_IP_ADDRESS_LIST, DELETE_IP_ADDRESS } from "../../Const/ActionTypes";
const initialState = {
  ipAddresses: [],
  newIpAddress: undefined,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_IP_ADDRESS_LIST: {
      return Object.assign({}, state, {
        ipAddresses: action.payload,
      });
    }
    case ADD_IP_ADDRESS: {
      return Object.assign({}, state, {
        ipAddresses: [action.payload, ...state.ipAddresses],
      });
    }
    case DELETE_IP_ADDRESS: {
      return Object.assign({}, state, {
        ipAddresses: [...state.ipAddresses.filter((element) => element.id !== action.payload)],
      });
    }
    default:
      return state;
  }
}
