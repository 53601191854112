import axios from 'axios';
import baseUrl from '../../Config/Config';
import {
  SIGNIN_USER_SUCCESS,
  SIGN_OUT_USER,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  REDIRECT_ACTION,
} from '../../Const/ActionTypes';
import {isEmail} from '../../Const/ValidationFunctions';
import {crudService} from '../../Services/CrudService';
import {getUser} from './UserAction';

export const userSignIn = (user, pathName) => {
  if (isEmail(user.email)) {
    return (dispatch) => {
      axios
        .post(`${baseUrl.baseUrl}${pathName}`, user)
        .then((res) => {
          localStorage.setItem('mUfjka563lPs', res.data.token);
          // let userData = jwt_decode(res.data.token);
          // dispatch({
          //   type: SIGNIN_USER_SUCCESS,
          //   payload: undefined,
          // });
          dispatch(getUser());
        })
        .catch((err) => {
          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload: err?.response?.data?.error?.message,
          });

          setTimeout(() => {
            dispatch({type: HIDE_ERROR_MESSAGE});
          }, 4000);
        });
    };
  } else {
    return (dispatch) =>
      dispatch({
        type: SHOW_ERROR_MESSAGE,
        payload: `Veuillez saisir une adresse email valide `,
      });
  }
};

export const userLogOut = () => {
  return (dispatch) => {
    let apiEndpoint = `/logout`;
    crudService
      .get(apiEndpoint)
      .then((res) => {
        if (res?.data?.redirectUrl) {
          localStorage.removeItem('mUfjka563lPs');
          window.location.href = res.data.redirectUrl;
        }
      })
      .catch((err) => {
        console.log('err: ', err);
      })
      .finally(() => window.scrollTo(0, 0));
  };
};

export const userNotAuthorised = () => {
  return (dispatch) => {
    localStorage.removeItem('mUfjka563lPs');
    dispatch({
      type: SIGN_OUT_USER,
      payload: null,
    });
  };
};

export const SaveUserProfile = (userData) => {
  return (dispatch) => {
    dispatch({
      type: SIGNIN_USER_SUCCESS,
      payload: userData,
    });
  };
};

export const resetPassword = (data, id, role) => {
  return (dispatch) => {
    axios
      .put(`${baseUrl.baseUrl}/users/reset_password`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.mUfjka563lPs}`,
        },
      })
      .then((res) => {
        let newUser = {
          email: data.email,
          password: data.password,
        };
        axios
          .post(`${baseUrl.baseUrl}/${role}/signin`, newUser)
          .then((response) => {
            localStorage.setItem('mUfjka563lPs', response.data.token);
            // let userData = jwt_decode(response.data.token);
            // dispatch({
            //   type: SIGNIN_USER_SUCCESS,
            //   payload: userData,
            // });
            dispatch(getUser());
            dispatch({
              type: SHOW_SUCCESS_MESSAGE,
              payload: 'Password successfully reset',
            });
            setTimeout(() => {
              dispatch({type: HIDE_SUCCESS_MESSAGE});
            }, 4000);
            window.scrollTo(0, 0);
          })
          .catch((err) => {
            dispatch({
              type: SHOW_ERROR_MESSAGE,
              payload: 'An error occurred while connecting please try again !',
            });
            setTimeout(() => {
              dispatch({type: HIDE_ERROR_MESSAGE});
            }, 4000);
          });
      })
      .catch((err) => {
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: err.response.data.error.message,
        });
        setTimeout(() => {
          dispatch({type: HIDE_ERROR_MESSAGE});
        }, 2000);
      })
      .finally(() => window.scrollTo(0, 0));
  };
};
export const forgotPassword = (user) => {
  return (dispatch) => {
    let apiEndpoint = `/users/forget_password`;
    crudService.post(apiEndpoint, user).then((res) => {
      if (res) {
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: `Your password has been successfully reset`,
        });
        setTimeout(() => {
          dispatch({type: HIDE_SUCCESS_MESSAGE});
          dispatch({
            type: REDIRECT_ACTION,
            payload: `/${res.data.role}/signin`,
          });
        }, 2000);
      }
    });
  };
};
export const updatePassword = (data) => {
  return (dispatch) => {
    axios
      .put(`${baseUrl.baseUrl}/users/reset_password`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.mUfjka563lPs}`,
        },
      })
      .then((res) => {
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: 'Password successfully reset',
        });
        setTimeout(() => {
          dispatch({type: HIDE_SUCCESS_MESSAGE});
        }, 2000);
        setTimeout(() => {
          dispatch(userLogOut());
        }, 3000);

        window.scrollTo(0, 0);
      })

      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? 'Please try again later, an error has occurred while updating a user'
            : err.response.data.error.message;
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({type: HIDE_ERROR_MESSAGE});
        }, 2000);
      })
      .finally(() => window.scrollTo(0, 0));
  };
};
