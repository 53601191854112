import moment from 'moment';

export const makeUsers = (data) => {
  let users = [];
  users = data.map((row) => {
    let start = moment(row.dateSessionStart).set({hour: 0, minute: 0, second: 0});
    let end = moment(row.dateSessionEnd).set({hour: 23, minute: 59, second: 59});
    let currentDay = moment();
    let days;

    if (currentDay.isAfter(start)) {
      if (currentDay.isAfter(end)) {
        days = 0;
      } else {
        days = end.diff(moment().set({hour: 0, minute: 0, second: 0}), 'days') + 1;
      }
    } else {
      days = end.diff(start, 'days') + 1;
    }

    return {...row, days};
  });
  return users;
};

export function createData(id, APIkeys, name, createdAt) {
  return {id, APIkeys, name, createdAt};
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export function validatePwd(pwd, patternMsg, valueMsg, noErrorMsg) {
  if (pwd.validity.patternMismatch) {
    pwd.setCustomValidity(patternMsg);
    return false;
  } else if (pwd.validity.valueMissing) {
    pwd.setCustomValidity(valueMsg);
    return false;
  } else {
    pwd.setCustomValidity(noErrorMsg);
    return true;
  }
}
