import React, {useState} from 'react';
// import FirstLogin from '../Authentification/FirstLogin';
import ApiKeys from './Apikeys/ApiKeys';
import IpAddress from './IpAddress/IpAddress';
import Settings from './Settings/Settings';
import SideNav from './SideNav';
import Users from './UserManagement/Users';

export default function Dashboard({defaultActiveItem}) {
  const [activeItem, setActiveItem] = useState(defaultActiveItem);

  return (
    <main className="main">
      <section className="dashboard d_flex">
        <SideNav activeItem={activeItem} setActiveItem={setActiveItem} />
        {activeItem === 'APIKeys' && <ApiKeys />}
        {activeItem === 'ApprovedIPs' && <IpAddress />}
        {activeItem === 'Settings' && <Settings />}
        {activeItem === 'Users' && <Users />}
      </section>
    </main>
  );
}
