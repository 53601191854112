import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {deleteUser} from '../../../Redux/Actions/UserAction';
import ConfirmModal from '../../Commun/ConfirmModal';
import UserItem from './UserItem';

export default function UserList({usersList, openUserForm}) {
  const dispatch = useDispatch();

  const [userToDelete, setUserToDelete] = useState({});

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDelete = (row) => {
    setDeleteModal(true);
    setUserToDelete(row);
  };

  const confirmDelete = () => {
    dispatch(deleteUser(userToDelete));
    setDeleteModal(false);
  };

  const handleEdit = (row) => {
    openUserForm('edit', row);
  };

  return (
    <>
      {deleteModal && (
        <ConfirmModal
          handleSubmit={confirmDelete}
          handleClose={setDeleteModal}
          modalMessage={'You are going to delete user with email : ' + userToDelete.email}
        />
      )}

      <table className="dashboard_table">
        <thead className="thead dashboard_thead">
          <tr>
            <td>
              <div className="dashboard_title">
                <svg
                  width={16}
                  height={16}
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.49724 9.99999H3.16924L1.8359 13.3333H0.399902L4.66657 2.66666H5.9999L10.2666 13.3333H8.83057L7.49724 9.99999ZM6.9639 8.66666L5.33324 4.58999L3.70257 8.66666H6.9639V8.66666ZM13.9999 8.35666V7.99999H15.3332V13.3333H13.9999V12.9767C13.5945 13.2105 13.1347 13.3335 12.6667 13.3333C12.1987 13.3332 11.739 13.2098 11.3338 12.9758C10.9285 12.7417 10.592 12.4051 10.3581 11.9997C10.1241 11.5944 10.0009 11.1347 10.0009 10.6667C10.0009 10.1987 10.1241 9.73891 10.3581 9.33358C10.592 8.92826 10.9285 8.59164 11.3338 8.35755C11.739 8.12347 12.1987 8.00015 12.6667 7.99999C13.1347 7.99983 13.5945 8.12284 13.9999 8.35666V8.35666ZM12.6666 12C13.0202 12 13.3593 11.8595 13.6094 11.6095C13.8594 11.3594 13.9999 11.0203 13.9999 10.6667C13.9999 10.313 13.8594 9.9739 13.6094 9.72385C13.3593 9.4738 13.0202 9.33332 12.6666 9.33332C12.3129 9.33332 11.9738 9.4738 11.7238 9.72385C11.4737 9.9739 11.3332 10.313 11.3332 10.6667C11.3332 11.0203 11.4737 11.3594 11.7238 11.6095C11.9738 11.8595 12.3129 12 12.6666 12V12Z"
                    fill="#7D8693"
                  />
                </svg>
                <span> Name</span>
              </div>
            </td>

            <td>
              <div className="dashboard_title">
                {/* <svg
                  width={16}
                  height={16}
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.49724 9.99999H3.16924L1.8359 13.3333H0.399902L4.66657 2.66666H5.9999L10.2666 13.3333H8.83057L7.49724 9.99999ZM6.9639 8.66666L5.33324 4.58999L3.70257 8.66666H6.9639V8.66666ZM13.9999 8.35666V7.99999H15.3332V13.3333H13.9999V12.9767C13.5945 13.2105 13.1347 13.3335 12.6667 13.3333C12.1987 13.3332 11.739 13.2098 11.3338 12.9758C10.9285 12.7417 10.592 12.4051 10.3581 11.9997C10.1241 11.5944 10.0009 11.1347 10.0009 10.6667C10.0009 10.1987 10.1241 9.73891 10.3581 9.33358C10.592 8.92826 10.9285 8.59164 11.3338 8.35755C11.739 8.12347 12.1987 8.00015 12.6667 7.99999C13.1347 7.99983 13.5945 8.12284 13.9999 8.35666V8.35666ZM12.6666 12C13.0202 12 13.3593 11.8595 13.6094 11.6095C13.8594 11.3594 13.9999 11.0203 13.9999 10.6667C13.9999 10.313 13.8594 9.9739 13.6094 9.72385C13.3593 9.4738 13.0202 9.33332 12.6666 9.33332C12.3129 9.33332 11.9738 9.4738 11.7238 9.72385C11.4737 9.9739 11.3332 10.313 11.3332 10.6667C11.3332 11.0203 11.4737 11.3594 11.7238 11.6095C11.9738 11.8595 12.3129 12 12.6666 12V12Z"
                    fill="#7D8693"
                  />
                </svg> */}
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 364.441 364.441"
                  style={{enableBackground: 'new 0 0 364.441 364.441'}}
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      style={{fill: '#C3C6C8'}}
                      d="M356.941,88.637c0-13.75-11.25-25-25-25H32.5c-13.75,0-25,11.25-25,25v187.168
		c0,13.75,11.25,25,25,25h299.441c13.75,0,25-11.25,25-25V88.637z"
                    />
                    <path
                      style={{fill: '#E8E8E8'}}
                      d="M182.221,156.933L7.5,268.704v7.1c0,13.75,11.25,25,25,25h299.441c13.75,0,25-11.25,25-25v-7.1
		L182.221,156.933z"
                    />
                    <path
                      style={{fill: '#E8E8E8'}}
                      d="M182.221,207.509l174.72-111.773v-7.1c0-13.75-11.25-25-25-25H32.5c-13.75,0-25,11.25-25,25v7.1
		L182.221,207.509z"
                    />
                    <path
                      style={{fill: '#005CB9'}}
                      d="M355.599,80.595L182.221,191.509L8.843,80.595C7.977,83.123,7.5,85.826,7.5,88.637v7.1
		l174.721,111.772l174.72-111.773v-7.1C356.941,85.826,356.465,83.123,355.599,80.595z"
                    />
                    <path
                      style={{fill: '#071C2C'}}
                      d="M331.941,56.137H32.5c-17.92,0-32.5,14.58-32.5,32.5v187.168c0,17.92,14.58,32.5,32.5,32.5h299.441
		c17.921,0,32.5-14.58,32.5-32.5V88.637C364.441,70.716,349.862,56.137,331.941,56.137z M15,109.438l113.773,72.783L15,255.004
		V109.438z M349.441,275.805c0,9.649-7.851,17.5-17.5,17.5H32.5c-9.649,0-17.5-7.851-17.5-17.5v-2.994l127.69-81.687l35.488,22.703
		c1.232,0.788,2.637,1.182,4.042,1.182s2.81-0.394,4.042-1.182l35.487-22.702l127.691,81.686v2.994H349.441z M349.441,255.003
		L235.668,182.22l113.773-72.783V255.003z M349.441,91.631l-167.22,106.974L15,91.631v-2.994c0-9.649,7.851-17.5,17.5-17.5h299.441
		c9.649,0,17.5,7.851,17.5,17.5V91.631z"
                    />
                  </g>
                </svg>

                <span>Email</span>
              </div>
            </td>

            <td colSpan={2}>
              <div className="dashboard_title">
                <svg
                  width={16}
                  height={16}
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.99992 0.666656V1.99999H9.99992V0.666656H11.3333V1.99999H13.9999C14.1767 1.99999 14.3463 2.07023 14.4713 2.19525C14.5963 2.32028 14.6666 2.48985 14.6666 2.66666V13.3333C14.6666 13.5101 14.5963 13.6797 14.4713 13.8047C14.3463 13.9298 14.1767 14 13.9999 14H1.99992C1.82311 14 1.65354 13.9298 1.52851 13.8047C1.40349 13.6797 1.33325 13.5101 1.33325 13.3333V2.66666C1.33325 2.48985 1.40349 2.32028 1.52851 2.19525C1.65354 2.07023 1.82311 1.99999 1.99992 1.99999H4.66659V0.666656H5.99992ZM13.3333 6.66666H2.66659V12.6667H13.3333V6.66666ZM10.0239 7.42399L10.9666 8.36666L7.66659 11.6667L5.30925 9.30932L6.25325 8.36666L7.66725 9.78132L10.0246 7.42399H10.0239ZM4.66659 3.33332H2.66659V5.33332H13.3333V3.33332H11.3333V3.99999H9.99992V3.33332H5.99992V3.99999H4.66659V3.33332Z"
                    fill="#7D8693"
                  />
                </svg>
                <span>Days left</span>
              </div>
            </td>
          </tr>
        </thead>
        <tbody className="dashboard_body">
          {usersList.map((userElement) => {
            return (
              <UserItem
                key={userElement.id}
                userData={userElement}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
}
