import React from 'react';
import {Link} from 'react-router-dom';

const NotFoundPage = ({role}) => {
  return (
    <div className="login">
      <div className='text_center'>
      <h1 style={{color: 'red', fontSize: 100}}>404</h1>
      <h3>Page Not Found</h3>
      <p>
        {role === 'admin' && <Link to="/admin/signin">Back Home</Link>}
        {role === 'developer' && <Link to="/developer/signin">Back Home</Link>}
      </p>
      </div>
    </div>
  );
};
export default NotFoundPage;
