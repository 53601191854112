import {
  GET_USERS_LIST,
  SUBMIT_USER,
  DELETE_USER,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  EDIT_USER,
  GET_USER,
  SIGNIN_USER_SUCCESS,
  ACTIVE_LOADER,
} from '../../Const/ActionTypes';
import _ from 'lodash';
import {crudService} from '../../Services/CrudService';

export const getUsers = () => {
  return (dispatch) => {
    let apiEndpoint = `/users`;
    crudService
      .get(apiEndpoint)
      .then((res) => {
        if (res) {
          let userDatataStored = res.data.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          dispatch({
            type: GET_USERS_LIST,
            payload: userDatataStored,
          });
        }
      })
      .catch((err) => {})
      .finally(() => window.scrollTo(0, 0));
  };
};

export const submitUser = (data) => {
  return (dispatch) => {
    let apiEndpoint = `/users/signup`;
    crudService
      .post(apiEndpoint, data)
      .then((res) => {
        if (res) {
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload: 'The creation of a new user has been successfully completed',
          });
          dispatch({
            type: SUBMIT_USER,
            payload: res.data,
          });
          setTimeout(() => {
            dispatch({type: HIDE_SUCCESS_MESSAGE});
          }, 4000);
        }
      })
      .finally(() => window.scrollTo(0, 0));
  };
};

export const editUser = (data) => {
  let newData = _.omit(data, ['id', 'days']);
  let userEmail = data.email;
  return (dispatch) => {
    let apiEndpoint = `/users/${data.id}`;
    crudService
      .put(apiEndpoint, newData)
      .then((res) => {
        if (res) {
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload: 'User with email ' + userEmail + ' has been updated',
          });
          dispatch({
            type: EDIT_USER,
            payload: {
              ...data,
              dateSessionStart: data.dateSessionStart,
              dateSessionEnd: data.dateSessionEnd,
            },
          });
          setTimeout(() => {
            dispatch({type: HIDE_SUCCESS_MESSAGE});
          }, 4000);
          window.scrollTo(0, 0);
        }
      })
      .finally(() => window.scrollTo(0, 0));
  };
};

export const deleteUser = (data) => {
  let userId = data.id;
  let userEmail = data.email;
  return (dispatch) => {
    let apiEndpoint = `/users/${userId}`;
    crudService
      .deleteEndpoint(apiEndpoint)
      .then((res) => {
        if (res) {
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload: 'User with email ' + userEmail + ' has been deleted',
          });
          dispatch({
            type: DELETE_USER,
            payload: userId,
          });
          setTimeout(() => {
            dispatch({type: HIDE_SUCCESS_MESSAGE});
          }, 4000);
          window.scrollTo(0, 0);
        }
      })
      .finally(() => window.scrollTo(0, 0));
  };
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({
      type: ACTIVE_LOADER,
      payload: true,
    });
    let apiEndpoint = `/users/me`;
    crudService
      .get(apiEndpoint)
      .then((res) => {
        if (res) {
          dispatch({
            type: SIGNIN_USER_SUCCESS,
            payload: res.data,
          });
          dispatch({
            type: GET_USER,
            payload: res.data,
          });
          dispatch({
            type: ACTIVE_LOADER,
            payload: false,
          });
        }
      })

      .catch((err) => {})
      .finally(() => window.scrollTo(0, 0));
  };
};
