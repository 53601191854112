import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./Reducers/UserReducer";
import authReducer from "./Reducers/AuthReducer";
import alertReducer from "./Reducers/AlertReducer";
import ApiKeyReducer from "./Reducers/ApiKeyReducer";
import IpAddressReducer from "./Reducers/IpAddressReducer";

// const customizedMiddleware = getDefaultMiddleware({
//   serializableCheck: false
// })
const rootReducer = combineReducers({
  userReducer,
  authReducer,
  alertReducer,
  ApiKeyReducer,
  IpAddressReducer,
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
