/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_USERS_LIST,
  SUBMIT_USER,
  EDIT_USER,
  DELETE_USER,
  GET_USER,
} from "../../Const/ActionTypes";
import { makeUsers } from "../../Const/ExploitableFunctions";

const initialState = {
  users: [],
  user: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS_LIST: {
      return Object.assign({}, state, {
        users: makeUsers(action.payload),
      });
    }
    case GET_USER: {
      return Object.assign({}, state, {
        user: action.payload,
      });
    }
    case DELETE_USER: {
      return Object.assign({}, state, {
        users: [
          ...state.users.filter((element) => element.id !== action.payload),
        ],
      });
    }
    case SUBMIT_USER: {
      return Object.assign({}, state, {
        users: makeUsers([action.payload, ...state.users]),
      });
    }
    case EDIT_USER: {
      let newList = state.users.map((element) =>
        element.id === action.payload.id
          ? {
              ...action.payload,
            }
          : element
      );
      return Object.assign({}, state, {
        users: makeUsers(newList),
      });
    }

    default:
      return state;
  }
}
