import {
  GET_IP_ADDRESS_LIST,
  ADD_IP_ADDRESS,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  DELETE_IP_ADDRESS,
  ACTIVE_LOADER,
} from "../../Const/ActionTypes";
import { crudService } from "../../Services/CrudService";

export const getIpAddresses = (userId) => {
  return (dispatch) => {
    dispatch({
      type: ACTIVE_LOADER,
      payload: true,
    });
    let apiEndpoint = `/approved-ips/user/${userId}`;
    crudService
      .get(apiEndpoint)
      .then((res) => {
        if (res) {
          let dataStored = res.data.sort(function (a, b) {
            return new Date(b.createAt) - new Date(a.createAt);
          });
          dispatch({
            type: GET_IP_ADDRESS_LIST,
            payload: dataStored,
          });
          dispatch({
            type: ACTIVE_LOADER,
            payload: false,
          });
        }
      })

      .finally(() => window.scrollTo(0, 0));
  };
};

export const addNewIpAddress = (data) => {
  return (dispatch) => {
    let apiEndpoint = `/approved-ips`;
    crudService
      .post(apiEndpoint, data)
      .then((res) => {
        if (res) {
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload: `A new IP address has been added successfully.`,
          });
          dispatch({
            type: ADD_IP_ADDRESS,
            payload: res.data,
          });
          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);
        }
      })
      .finally(() => window.scrollTo(0, 0));
  };
};

export const deleteIpAddress = (ipAddress) => {
  return (dispatch) => {
    let apiEndpoint = `/approved-ips/${ipAddress.id}`;
    crudService
      .deleteEndpoint(apiEndpoint)
      .then((res) => {
        if (res) {
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload: `IP address deleted successfully`,
          });
          dispatch({
            type: DELETE_IP_ADDRESS,
            payload: ipAddress.id,
          });
          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);
        }
      })

      .finally(() => window.scrollTo(0, 0));
  };
};
