import React from "react";

export default function ConfirmModal({ ...props }) {
  return (
    <div className="overlay active">
      <div className="overlay_form_wrapper">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.handleSubmit(false);
          }}
          className="overlay_form form_api"
        >
          <div className="overlay_title"> {props.modalMessage} </div>

          <div className="overlay_group d_flex">
            <button
              className="btn btn_primary btn_rectangle overlay_save "
              type="submit"
            >
              Confirm
            </button>
            <button
              className="btn btn_light btn_rectangle overlay_cancel"
              onClick={() => {
                props.handleClose(false);
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
